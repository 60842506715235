import { TextStyle } from 'react-native'
import { createUseStyles } from '../../styles/create-use-styles'
import { useGenerateTokens, TextTokens } from './useGenerateTokens.web'

import type { TextProps, TextDescriptions } from './text.web.props'
import { TextDescriptionVariant } from './constants.web'

interface TextStyles {
  text: TextStyle
}

const getTokenForDescription = (tokens: TextTokens, textDescription: TextDescriptions) => {
  switch (textDescription) {
    // Primary Fonts (ROBOTO)
    case TextDescriptionVariant.XXSMALL_PRIMARY_BOLD:
      return tokens.XXSmallPrimaryBold
    case TextDescriptionVariant.XXSMALL_ITALIC_PRIMARY_BOLD:
      return tokens.XXSmallItalicPrimaryBold

    case TextDescriptionVariant.XSMALL_PRIMARY_REGULAR:
      return tokens.XSmallPrimaryRegular
    case TextDescriptionVariant.XSMALL_PRIMARY_MEDIUM:
      return tokens.XSmallPrimaryMedium
    case TextDescriptionVariant.XSMALL_PRIMARY_BOLD:
      return tokens.XSmallPrimaryBold
    case TextDescriptionVariant.XSMALL_ITALIC_PRIMARY_BOLD:
      return tokens.XSmallItalicPrimaryBold
    case TextDescriptionVariant.XSMALL_PRIMARY_XBOLD:
      return tokens.XSmallPrimaryXBold

    case TextDescriptionVariant.SMALL_PRIMARY_REGULAR:
      return tokens.SmallPrimaryRegular
    case TextDescriptionVariant.SMALL_ITALIC_PRIMARY_REGULAR:
      return tokens.SmallItalicPrimaryRegular
    case TextDescriptionVariant.SMALL_PRIMARY_MEDIUM:
      return tokens.SmallPrimaryMedium
    case TextDescriptionVariant.SMALL_PRIMARY_BOLD:
      return tokens.SmallPrimaryBold
    case TextDescriptionVariant.SMALL_PRIMARY_XBOLD:
      return tokens.SmallPrimaryXBold

    case TextDescriptionVariant.REGULAR_PRIMARY_LIGHT:
      return tokens.RegularPrimaryLight
    case TextDescriptionVariant.REGULAR_PRIMARY_REGULAR:
      return tokens.RegularPrimaryRegular
    case TextDescriptionVariant.REGULAR_PRIMARY_MEDIUM:
      return tokens.RegularPrimaryMedium
    case TextDescriptionVariant.REGULAR_PRIMARY_BOLD:
      return tokens.RegularPrimaryBold
    case TextDescriptionVariant.REGULAR_PRIMARY_XBOLD:
      return tokens.RegularPrimaryXBold

    case TextDescriptionVariant.MEDIUM_PRIMARY_LIGHT:
      return tokens.MediumPrimaryLight
    case TextDescriptionVariant.MEDIUM_PRIMARY_REGULAR:
      return tokens.MediumPrimaryRegular
    case TextDescriptionVariant.MEDIUM_PRIMARY_MEDIUM:
      return tokens.MediumPrimaryMedium
    case TextDescriptionVariant.MEDIUM_PRIMARY_SEMIBOLD:
      return tokens.MediumPrimarySemiBold
    case TextDescriptionVariant.MEDIUM_PRIMARY_BOLD:
      return tokens.MediumPrimaryBold
    case TextDescriptionVariant.MEDIUM_PRIMARY_XBOLD:
      return tokens.MediumPrimaryXBold

    case TextDescriptionVariant.XMEDIUM_PRIMARY_REGULAR:
      return tokens.XMediumPrimaryRegular
    case TextDescriptionVariant.XMEDIUM_ITALIC_PRIMARY_REGULAR:
      return tokens.XMediumItalicPrimaryRegular
    case TextDescriptionVariant.XMEDIUM_PRIMARY_BOLD:
      return tokens.XMediumPrimaryBold
    case TextDescriptionVariant.XMEDIUM_ITALIC_PRIMARY_BOLD:
      return tokens.XMediumItalicPrimaryBold
    case TextDescriptionVariant.XMEDIUM_PRIMARY_SEMIBOLD:
      return tokens.XMediumPrimarySemiBold

    case TextDescriptionVariant.LARGE_PRIMARY_REGULAR:
      return tokens.LargePrimaryRegular
    case TextDescriptionVariant.LARGE_PRIMARY_BOLD:
      return tokens.LargePrimaryBold
    case TextDescriptionVariant.LARGE_PRIMARY_XBOLD:
      return tokens.LargePrimaryXBold

    case TextDescriptionVariant.XLARGE_PRIMARY_REGULAR:
      return tokens.XLargePrimaryRegular
    case TextDescriptionVariant.XLARGE_PRIMARY_BOLD:
      return tokens.XLargePrimaryBold

    case TextDescriptionVariant.XXLARGE_PRIMARY_BOLD:
      return tokens.XXLargePrimaryBold
    case TextDescriptionVariant.XXLARGE_PRIMARY_XBOLD:
      return tokens.XXLargePrimaryXBold

    case TextDescriptionVariant.JUMBO_PRIMARY_REGULAR:
      return tokens.JumboPrimaryRegular
    case TextDescriptionVariant.JUMBO_PRIMARY_BOLD:
      return tokens.JumboPrimaryBold
    case TextDescriptionVariant.JUMBO_PRIMARY_XBOLD:
      return tokens.JumboPrimaryXBold

    case TextDescriptionVariant.XXJUMBO_PRIMARY_REGULAR:
      return tokens.XXJumboPrimaryRegular
    case TextDescriptionVariant.XXJUMBO_PRIMARY_BOLD:
      return tokens.XXJumboPrimaryBold

    // Secondary Fonts (PFF SANS)
    case TextDescriptionVariant.XSMALL_SECONDARY_REGULAR:
      return tokens.XSmallSecondaryRegular
    case TextDescriptionVariant.XSMALL_SECONDARY_MEDIUM:
      return tokens.XSmallSecondaryMedium
    case TextDescriptionVariant.XSMALL_SECONDARY_SEMIBOLD:
      return tokens.XSmallSecondarySemiBold
    case TextDescriptionVariant.XSMALL_ITALIC_SECONDARY_SEMIBOLD:
      return tokens.XSmallItalicSecondarySemiBold
    case TextDescriptionVariant.XSMALL_SECONDARY_BOLD:
      return tokens.XSmallSecondaryBold
    case TextDescriptionVariant.XSMALL_ITALIC_SECONDARY_XBOLD:
      return tokens.XSmallItalicSecondaryXBold

    case TextDescriptionVariant.SMALL_SECONDARY_REGULAR:
      return tokens.SmallSecondaryRegular
    case TextDescriptionVariant.SMALL_SECONDARY_MEDIUM:
      return tokens.SmallSecondaryMedium
    case TextDescriptionVariant.SMALL_ITALIC_SECONDARY_MEDIUM:
      return tokens.SmallItalicSecondaryMedium
    case TextDescriptionVariant.SMALL_SECONDARY_SEMIBOLD:
      return tokens.SmallSecondarySemiBold
    case TextDescriptionVariant.SMALL_ITALIC_SECONDARY_SEMIBOLD:
      return tokens.SmallItalicSecondarySemiBold

    case TextDescriptionVariant.REGULAR_SECONDARY_MEDIUM:
      return tokens.RegularSecondaryMedium
    case TextDescriptionVariant.REGULAR_SECONDARY_SEMIBOLD:
      return tokens.RegularSecondarySemiBold
    case TextDescriptionVariant.REGULAR_ITALIC_SECONDARY_BOLD:
      return tokens.RegularItalicSecondaryBold

    case TextDescriptionVariant.MEDIUM_SECONDARY_REGULAR:
      return tokens.MediumSecondaryRegular
    case TextDescriptionVariant.MEDIUM_SECONDARY_SEMIBOLD:
      return tokens.MediumSecondarySemiBold
    case TextDescriptionVariant.MEDIUM_ITALIC_SECONDARY_SEMIBOLD:
      return tokens.MediumItalicSecondarySemiBold
    case TextDescriptionVariant.MEDIUM_SECONDARY_BOLD:
      return tokens.MediumSecondaryBold

    case TextDescriptionVariant.LARGE_SECONDARY_SEMIBOLD:
      return tokens.LargeSecondarySemiBold
    case TextDescriptionVariant.LARGE_ITALIC_SECONDARY_SEMIBOLD:
      return tokens.LargeItalicSecondarySemiBold
    case TextDescriptionVariant.LARGE_SECONDARY_BOLD:
      return tokens.LargeSecondaryBold

    case TextDescriptionVariant.XLARGE_SECONDARY_BOLD:
      return tokens.XLargeSecondaryBold
    case TextDescriptionVariant.XLARGE_ITALIC_SECONDARY_BOLD:
      return tokens.XLargeItalicSecondaryBold

    case TextDescriptionVariant.XXLARGE_SECONDARY_BOLD:
      return tokens.XXLargeSecondaryBold

    case TextDescriptionVariant.JUMBO_SECONDARY_SEMIBOLD:
      return tokens.JumboSecondarySemiBold
    case TextDescriptionVariant.JUMBO_SECONDARY_BOLD:
      return tokens.JumboSecondaryBold

    case TextDescriptionVariant.XJUMBO_SECONDARY_BOLD:
      return tokens.XJumboSecondaryBold

    case TextDescriptionVariant.XXJUMBO_SECONDARY_BOLD:
      return tokens.XXJumboSecondaryBold

    case TextDescriptionVariant.XXLJUMBO_SECONDARY_BOLD:
      return tokens.XXLJumboSecondaryBold

    case TextDescriptionVariant.XXXLJUMBO_SECONDARY_BOLD:
      return tokens.XXXLJumboSecondaryBold

    case TextDescriptionVariant.XXXL_BIG_JUMBO_SECONDARY_BOLD:
      return tokens.XXXLBigJumboSecondaryBold

    // WEB Secondary Fonts (Barlow)
    case TextDescriptionVariant.XLARGE_WEB_SECONDARY_BOLD:
      return tokens.XLargeWebSecondaryBold

    default:
      return tokens.MediumPrimaryRegular
  }
}

export const useStyles = createUseStyles<TextStyles, TextProps>((theme, props): TextStyles => {
  const {
    colorOverride = null,
    textAlign,
    variant,
    uppercase,
    underline,
    strikethrough,
    capitalize,
    textWrap,
    lineHeightOverride = null,
  } = props
  const tokens = useGenerateTokens(theme)

  const textStyle = getTokenForDescription(tokens, variant)
  if (textAlign) {
    textStyle.textAlign = textAlign
  }

  if (uppercase) {
    textStyle.textTransform = 'uppercase'
  }

  if (underline) {
    textStyle.textDecorationLine = 'underline'
  }

  if (capitalize) {
    textStyle.textTransform = 'capitalize'
  }

  if (colorOverride) {
    textStyle.color = colorOverride
  }

  if (strikethrough) {
    textStyle.textDecorationLine = 'line-through'
  }

  if (lineHeightOverride) {
    textStyle.lineHeight = theme.calcUnit(lineHeightOverride)
  }

  if (textWrap) {
    // @ts-expect-error
    textStyle.textWrap = textWrap
    // @ts-expect-error
    textStyle.whiteSpace = textWrap.toLowerCase()

    if (textWrap === 'wrap') {
      // @ts-expect-error
      textStyle.overflowWrap = 'anywhere'
    }
  }

  return {
    text: textStyle,
  }
})
